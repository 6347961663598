import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	message: {
		width: "100%",
		fontSize: "1em",
		backgroundColor: "var(--user-msg-txt)",
		textAlign: "right",
	},
	messageBody: {
		padding: "5px 10px",
		backgroundColor: "var(--active-msg-bg)",
		color: "var(--user-msg-txt)",
		display: "inline-block",
		borderRadius: "6px",
		marginRight: "0px",
		marginLeft: "50px",
		textAlign: "left",
		overflowWrap: "break-word",
		maxWidth: "75%",
		"-moz-box-shadow": "0 1px 2px rgba(0, 0, 0, 0.28)",
		"-webkit-box-shadow": "0 1px 2px rgba(0, 0, 0, 0.28)",
		boxShadow: "0 1px 2px rgba(0, 0, 0, 0.28)",
	},
	system: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start",
		textAlign: "left",
		margin: "5px 0 16px 0",
	},
	systemBody: {
		backgroundColor: "var(--bot-msg-bg)",
		color: "var(--bot-msg-txt)",
		marginLeft: "0px",
		marginRight: "50px",
	},
	avatarPlaceholder: {
		alignSelf: "flex-end",
		height: "30px",
		width: "30px",
		marginRight: "12px",
	},
	loader: {
		height: "6px",
		width: "6px",
		display: "block",
		borderRadius: "50%",
		margin: "10px 15px",
		position: "relative",
		boxShadow: "-12px 0 #fff, 12px 0 #fff",
		animation: "shadowPulse 1s ease infinite",
	},
});
export default function MessageLoader() {
	const classes = useStyles();
	return (
		<div>
			<div className={`${classes.message} ${classes.system}`}>
				<div className={classes.avatarPlaceholder}></div>
				<div className={`${classes.messageBody} ${classes.systemBody}`}>
					<div className={classes.loader}></div>
				</div>
			</div>
		</div>
	);
}
