import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
	root: {
		fontSize: "calc(var(--active-font-size) - 2px)",
		boxShadow: "none",
		margin: "5px 5px",
		minWidth: "110px",
		padding: "7px",

		"&:hover": {
			background: "white",
			opacity: 0.8,
		},
		"&:disabled": {
			borderColor: "var(--color-light-grey)",
		},
	},
	selected: {
		background: "var(--active-brand-color)",
		color: "white",
		"&:disabled": {
			color: "white",
		},
	},
});

export default function ResponseButton({
	children,
	submitResponse,
	messageId,
	selected,
	value,
	online,
	linkMap,
	chatbotIsDraft
}) {
	const classes = useStyles();

	function newTab() {
		window.open(linkMap, "_blank", "noreferrer");
		document.activeElement.blur();
	}

	return (
		<div>
			<Button
				disabled={!online || selected ? true : false || chatbotIsDraft}
				onClick={() => value === "View on Map" ? newTab() : submitResponse(messageId, value)}
				className={
					selected === children
						? `${classes.root} ${classes.selected}`
						: classes.root
				}
			>
				{children}
			</Button>
		</div>
	);
}
