import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

const useStyles = makeStyles({
	root: {
    maxWidth: 345,
		minWidth: 345,
		"&:hover": {
			border: "#2E56F4",
			cursor: "pointer",

			"& button": {
				"&:hover": {
					opacity: "0.8",
				}
			}
		}
  },
  media: {
    height: 200,
  },
	card: {
		width: "100%",
		borderRadius: "4px",
		border: "1px",
		boxSizing: "border-box",

		"&:hover": {
			border: "#2E56F4",
			cursor: "pointer",

			"& button": {
				"&:hover": {
					opacity: "0.8",
				}
			}
		}
	},
	cardBody: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		margin: "0",
		padding: "0",
		border: "none",
		borderTopLeftRadius: "4px",
		borderTopRightRadius: "4px",
		backgroundColor: "#E2E6F5",
		backgroundSize: "cover",
		boxSizing: "border-box",
	},
	cardFooter: {
		overflow: "hidden",
		textOverflow: "ellipsis",
		fontWeight: "bold",
		padding: "10.5px 16px",
		color: "black",
		boxSizing: "border-box",
		lineHeight: "21.6px",
		letterSpacing: "0.01071em",
		borderBottomLeftRadius: "inherit",
		borderBottomRightRadius: "inherit",
	},
	modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  modalContent: {
    backgroundColor: "#fff",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
    padding: "16px",
    outline: "none",
    maxWidth: "90vw",
    maxHeight: "90vh",
    overflow: "auto",
    "& img": {
      width: "100%",
      height: "auto"
    }
  },
  image: {
    width: "100%",
    height: "auto",
    cursor: "pointer"
  }
});

export default function CustomCard({
	text,
	metatag,
	isMap,
	mapSVG,
	setLastMsgPaint,
}) {
	const classes = useStyles(metatag);
	const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

	//Image logic to detect when the image load or error (not loaded)
	//to make the messages scroll to the end
	useEffect(() => {
		if (setLastMsgPaint) {
			setLastMsgPaint(true)
		}
	}, [setLastMsgPaint])

	//SVG map
	let newSVG = "";
	if (isMap === true) {
		newSVG = mapSVG[0]?.mapSVG[0].replace(/[\\]/g, '');
		newSVG = newSVG.replace("xmlns:xlink", "xmlnsXlink");
	}

	return (
    <div>
      <Card className={classes.root}>
        <CardMedia
          className={classes.media}
          image={metatag[0].image}
          title={text}
          onClick={handleOpen}
        />
        <CardContent>
        </CardContent>
      </Card>
			<Modal open={open} onClose={handleClose} className={classes.modal}>
         <div className={classes.modalContent}>
	 				<img src={metatag[0].image} alt="step" />
         </div>
       </Modal>
    </div>
  );

}
