const { FONT_SIZE } = require("../constants/fontSize.constants.js");
const { VISIBILITY } = require("../constants/visibility.constants.js");

const resizeGlobalFonts = (size) => {
	const updateStyle = document.body.style;

	if (size === FONT_SIZE.SMALL) {
		updateStyle.setProperty("--active-font-size", "var(--small-font-size)");
	} else if (size === FONT_SIZE.STANDARD) {
		updateStyle.setProperty("--active-font-size", "var(--default-font-size)");
	} else {
		updateStyle.setProperty("--active-font-size", "var(--large-font-size)");
	}
};
const updateVisibilitySetting = (visibility, colorSettings) => {
	const updateStyle = document.body.style;

	if (visibility === VISIBILITY.MONOCHROME) {
		updateStyle.setProperty("--active-brand-color", "var(--monochrome-text)");
		updateStyle.setProperty("--active-msg-bg", "var(--monochrome-text)");
	} else if (visibility === VISIBILITY.HIGH_CONTRAST) {
		updateStyle.setProperty(
			"--active-brand-color",
			"var(--active-high-contrast)"
		);
		updateStyle.setProperty("--active-msg-bg", "var(--active-high-contrast)");
	} else {
		updateStyle.setProperty("--active-brand-color", colorSettings.primary);
		updateStyle.setProperty("--active-msg-bg", colorSettings.accent);
	}
};

const resetAccessibilitySettings = (colorSettings, clientId) => {
	const updateStyle = document.body.style;
	updateStyle.setProperty("--active-font-size", "var(--default-font-size)");
	updateStyle.setProperty("--active-brand-color", colorSettings.primary);

	//checks if color was set in chatbot custom settings panel.
	//If it was -> we use the same color for primary and accent.

	if (colorSettings.primary === "#014ee2" || clientId) {
		updateStyle.setProperty("--active-msg-bg", colorSettings.accent);
	} else {
		updateStyle.setProperty("--active-msg-bg", colorSettings.primary);
	}
};

module.exports = {
	resizeGlobalFonts,
	updateVisibilitySetting,
	resetAccessibilitySettings,
};
