import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  root: {
    fontSize: "calc(var(--active-font-size) - 2px)",
    boxShadow: "none",
    // height: 40, //commented on May 9 to let the ResponseButton increase its height and keep the correct padding also if the text inside is long
    margin: "5px 5px",
    minWidth: "110px",
    // transition: "all 0.3s ease-in",
    padding: "7px",

		"&:hover": {
			// background: "white",
			background: "var(--active-brand-color)",
			opacity: 0.8,
			color: "white"
		},
		"&:disabled": {
			borderColor: "var(--color-light-grey)",
		},
	},
	selected: {
		background: "var(--active-brand-color)",
		color: "white",
		"&:disabled": {
			color: "white",
		},
	},
});

export default function AnswerButton({
	children,
	submitResponse,
	selectResponse,
	messageId,
	selected,
	value,
	online,
	linkMap,
	chatbotIsDraft
}) {
	const classes = useStyles();

	function newTab(){	
		// console.log("NEW TAB")	
		window.open(linkMap, "_blank", "noreferrer");	
		document.activeElement.blur();	
	}

	return (
		<div>
			<Button
				// disabled={!online || selected ? true : false || chatbotIsDraft}
				// onClick={() => submitResponse(messageId, value)}
				onClick={() => value === "View on Map" ? newTab() : selectResponse(messageId, value)}
				className={
					// selected === children
					selected.includes(value)
 					? `${classes.root} ${classes.selected}`
						: classes.root
				}
				style={{
				// 	borderRadius: '15px',
				// 	border: '3px solid',
				// 	fontWeight: '600',
				boxShadow: "none",
				}}
			>
				{children}
			</Button>
		</div>
	);
}
