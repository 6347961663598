import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { StateProvider } from "./context/state/provider";
import reducer, { initialState } from "./context/state/reducer";
import "./styles/variables.css";
import "./styles/index.css";
import reportWebVitals from "./reportWebVitals";
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from '../src/helpers/authConfig';

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <StateProvider initialState={initialState} reducer={reducer}>
        <App />
      </StateProvider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
