import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import 'firebase/database';

const firebaseConfig = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
	measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
auth.setPersistence(firebase.auth.Auth.Persistence.SESSION); //each tabs has different uid; uid store in session
// auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL); //same uid in different tabs; uid store in indexDB

const firestore = firebase.firestore();
const functions = firebase.functions();
const db = firebase.database();

//Use firebase emualator when running React locally.
if (window.location.hostname === "localhost") {
	functions.useEmulator("http://localhost:5001");
	firestore.useEmulator("localhost", 8080);
	auth.useEmulator("http://localhost:9099/", { disableWarnings: true });
}
export { firestore, auth, functions, db };

export const signInWithGoogle = async () => {
	const provider = new firebase.auth.GoogleAuthProvider();
	try {
		await auth.signInWithPopup(provider);
		return auth;
	} catch (err) {
	}
};

export const getUserOnLoad = async () => {
	return new Promise(function (resolve, reject) {
		var user = auth.currentUser;

		if (user) {
			resolve(user);
		} else {
			auth.signInAnonymously()
				.then((user) => {
					resolve(user.user);
				})
				.catch((err) => {
					reject("error");
				});
		}
	});
};

export const getUserStatus = async () => {
	console.log("getUserStatus");

	// Get the current user ID or generate a unique ID for anonymous users
	const userId = firebase.auth().currentUser?.uid;

	if (userId) {
		// Update the user status to "online"
		updateUserStatus(userId, 'online');

		// Set up a listener for user presence changes
		const authStateChangedListener = firebase.auth().onAuthStateChanged((user) => {
			console.log("getUserStatus userId", user?.uid);
			if (user) {
				// User is signed in
				updateUserStatus(user.uid, 'online');
				console.log("getUserStatus online");
			} else {
				// User is signed out
				updateUserStatus(userId, 'offline');
				console.log("getUserStatus offline");
			}
		});

		// Set up a listener for beforeunload and unload events
		const handleUnload = () => {
			console.log("handleUnload");
			updateUserStatus(userId, 'offline');
		};

		window.addEventListener('beforeunload', handleUnload);
		window.addEventListener('unload', handleUnload);

		// Return the cleanup function
		return () => {
			console.log("cleanup getUserStatus");
			authStateChangedListener(); // Unsubscribe onAuthStateChanged listener
			window.removeEventListener('beforeunload', handleUnload);
			window.removeEventListener('unload', handleUnload);
		};
	} else {
		console.log("User ID not available. Skipping user status update.");
		return () => { }; // Return an empty cleanup function if userId is not available
	}
};

// Function to update user status
const updateUserStatus = (userId, status) => {
	const userRef = firebase.firestore().collection('rooms').doc(userId);
	userRef.update({ status });
};
