import { makeStyles } from "@material-ui/core/styles";
import smallRobotAvatar from "../images/robotSmall.svg";
import smallUserAvatar from "../images/userIcon.svg";
import liveAgentAvatar from "../images/live-agent.svg";
import ResponseBtnGroup from "./ResponseBtnGroup.js";
import Box from "@material-ui/core/Box";
import FeedbackButton from "../components/FeedbackButton";
import { firestore } from "../services/firebase/firebase";
import { useState, useEffect, useRef } from "react";
import Card from "./Card"
import { useStateValue } from "../context/state/provider";
import axios from "axios";
import firebase from "firebase/app";

// const useStyles = makeStyles((theme) => ({
// 	formContainer: {
// 		maxWidth: '546px',
// 		marginLeft: '0px',
// 		marginRight: '50px',
// 	},
// 	form: {
// 		borderRadius: '6px',
// 		background: theme.palette.grey[200], // Assuming you are using Material-UI's theme with palette.grey[200] as the neutral background color.
// 		padding: '16px',
// 	},
// 	formGroup: {
// 		display: 'flex',
// 		flexDirection: 'column',
// 		marginBottom: '16px',
// 	},
// 	label: {
// 		fontWeight: 400,
// 		marginBottom: '5px',
// 	},
// 	input: {
// 		padding: '10px',
// 		border: `1px solid ${theme.palette.grey[300]}`, // Assuming you are using Material-UI's theme with palette.grey[300] as the border color.
// 		borderRadius: '5px',
// 	},
// 	errorMessage: {
// 		color: 'red',
// 		fontSize: '12px',
// 		marginTop: '5px',
// 	},
// 	link: {
// 		color: 'blue',
// 	},
// 	checkboxGroup: {
// 		display: 'flex',
// 		alignItems: 'center',
// 	},
// 	checkboxLabel: {
// 		marginLeft: '10px',
// 	},
// 	submitBtn: {
// 		padding: '10px 0',
// 		backgroundColor: '#2E56F4',
// 		color: '#fff',
// 		border: 'none',
// 		borderRadius: '6px',
// 		cursor: 'not-allowed',
// 		width: '100%',
// 		opacity: 0.5,
// 		fontSize: '16px',

// 		'&:hover': {
// 			backgroundColor: '#0056b3',
// 		},
// 	},
// 	enabled: {
// 		opacity: 1,
// 		cursor: 'pointer',
// 	}
// }));

const useStyles = makeStyles((theme) => ({
  formContainer: {
    maxWidth: '546px',
    marginLeft: '0px',
    marginRight: '50px',
  },
  form: {
    borderRadius: '6px',
    background: theme.palette.grey[200],
    padding: '16px',
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '16px',
  },
  label: {
    fontWeight: 400,
    marginBottom: '5px',
    fontSize: '16px', // Added to prevent zoom on iOS
  },
  input: {
    padding: '10px',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '5px',
    fontSize: '16px', // Added to prevent zoom on iOS
  },
  errorMessage: {
    color: 'red',
    fontSize: '12px',
    marginTop: '5px',
    fontSize: '16px', // Adjusted to prevent zoom on iOS
  },
  link: {
    color: 'blue',
    fontSize: '16px', // Adjusted to prevent zoom on iOS
  },
  checkboxGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  checkboxLabel: {
    marginLeft: '10px',
    fontSize: '16px', // Added to prevent zoom on iOS
  },
  submitBtn: {
    padding: '10px 0',
    backgroundColor: '#2E56F4',
    color: '#fff',
    border: 'none',
    borderRadius: '6px',
    cursor: 'not-allowed',
    width: '100%',
    opacity: 0.5,
    fontSize: '16px',
    '&:hover': {
      backgroundColor: '#0056b3',
    },
  },
  enabled: {
    opacity: 1,
    cursor: 'pointer',
  },
}));

export default function FormUserData({
	data,
	chatbotId,
	...props
}) {
	const classes = useStyles();

	const nameInputRef = useRef(null);
	const emailInputRef = useRef(null);

	const [name, setName] = useState(data?.name ? data?.name : '');
	const [email, setEmail] = useState(data?.email ? data?.email : '');
	const [agree, setAgree] = useState(data?.agree ? data?.agree : false);
	const [isFormValid, setIsFormValid] = useState(false);
	const [disableBtn, setDisableBtn] = useState(data?.name && data?.email && data?.agree ? true : false);

	const [nameInvalid, setNameInvalid] = useState(false);
	const [emailInvalid, setEmailInvalid] = useState(false);

	useEffect(() => {
		if (!disableBtn) {
			setIsFormValid(!nameInvalid && name.trim().length > 0 && !emailInvalid && email.trim().length > 0 && agree);
		}
	}, [name, email, agree, emailInvalid, nameInvalid, disableBtn]);


	useEffect(() => {
		console.log("Data", data)
		if (data.name) setName(data.name)
		if (data.email) setEmail(data.email)
		if (data.agree) setAgree(data.agree)
		if (data.name && data.email && data.agree) setDisableBtn(true)
	}, [])

	const handleChangeName = (event) => {
		const tmpName = event.target.value;
		console.log("handleChangeName", tmpName)
		setName(tmpName);
		// Allow letters (A-Z and a-z), spaces, and single quotes (')
		const nameRegex = /^[A-Za-z '-]+$/;
		const tmpState = tmpName.trim().length > 0 && nameRegex.test(tmpName.trim());
		setNameInvalid(!tmpState)
	};

	const handleChangeEmail = (event) => {
		const tmpEmail = event.target.value;
		console.log("handleChangeEmail", tmpEmail)
		setEmail(tmpEmail);
		const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const tmpState = tmpEmail.trim().length > 0 && emailRegex.test(tmpEmail.trim());
		setEmailInvalid(!tmpState)
	};

	const handleChangeAgree = (event) => {
		setAgree(event.target.checked);
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		const user = firebase.auth().currentUser;
		console.log("user", user)

		if (user) {
			setDisableBtn(true)
			// User is signed in, so get the authentication token
			//if values are valid before submit them trim all extra spaces to show the value that will be send and saved in database
			const regex = /\s+/g;
			const trimmedName = name.replace(regex, ' ').trim();
			const trimmedEmail = email.replace(regex, ' ').trim();

			user.getIdToken()
				.then((authToken) => {
					console.log("authToken", authToken)
					axios({
						method: "put",
						url: `${process.env.REACT_APP_FUNCTIONS_BASE_URL}/app/contact/${user.uid}***${chatbotId}`, //for DEV, STAGING, PRODUCTION
						// url: `http://localhost:5001/sail-dev-62f69/us-central1/app/contact/${user.uid}`, //for EMULATOR
						data: { FirstName: trimmedName, Email: trimmedEmail, agree },
						headers: {
							Authorization: 'Bearer ' + authToken, // Pass the UID in the Authorization header
						},
					}
					)
						.then((res) => {
							setName(res.data.data.FirstName)
							setAgree(res.data.data.agree)
							if (emailInputRef.current) {
								emailInputRef.current.value = res.data.data.Email;
							}
						}).catch((error) => {
							// An error ocurred
							console.log(" ERRR: ", error)
						});
					// Do the form submission here using the data stored in 'name', 'email', and 'agree'.
					// For example, you can send a request to the server with this data.
					// props.onSubmit({ name, email, agree });
				})
				.catch((error) => {
					// Handle error getting the authentication token
					// setIsLoading(false);
					console.log("error", error)
				});
		}
	};

	return (
		<div className={classes.formContainer}>
			<form id="chat-form" onSubmit={handleSubmit} className={classes.form}>
				<div className={classes.formGroup}>
					<label htmlFor="name">
						First Name <span style={{ color: "#D00" }}>*</span>
					</label>
					<input
						type="text"
						id="name"
						placeholder="Enter first name"
						required
						maxLength="20"
						pattern="^[A-Za-z '-]+$"
						value={name}
						onChange={handleChangeName}
						className={classes.input}
						ref={nameInputRef}
						disabled={disableBtn}
					/>
					{
						nameInvalid && <div className={classes.errorMessage} id="name-error">Name must contain only letters and cannot be empty.</div>}
				</div>
				<div className={classes.formGroup}>
					<label htmlFor="email">
						Email Address <span style={{ color: "#D00" }}>*</span>
					</label>
					<input
						type="email"
						id="email"
						placeholder="Enter email address"
						required
						value={email}
						onChange={handleChangeEmail}
						className={classes.input}
						ref={emailInputRef}
						disabled={disableBtn}
					/>
					{emailInvalid && <div className={classes.errorMessage} id="email-error">Please enter a valid email address.</div>}
				</div>
				<div>
					<p>
						I agree to have my personal data processed for chat support.{" "}
						{/* <a href="https://firebasestorage.googleapis.com/v0/b/sail-dev-62f69.appspot.com/o/public%2FPolicy%20Example.pdf?alt=media&token=c570cce8-053c-418d-b281-c712ff8eef01" target="_blank" rel="noreferrer">Full policy</a> */}
						<a href="https://sail.cx/privacy-policy/" target="_blank" rel="noreferrer">Full policy</a>
						<span style={{ color: "#D00" }}> *</span>
					</p>
				</div>
				<div className={`${classes.formGroup} ${classes.checkboxGroup}`} style={{ flexDirection: "row" }}>
					<input type="checkbox" id="agree" required style={{ marginLeft: "0px" }} checked={agree} onChange={handleChangeAgree} disabled={disableBtn} />
					<label htmlFor="agree" className={classes.checkboxLabel} style={{ marginBottom: "0px", marginLeft: "6px" }}>
						Agree
					</label>
				</div>
				<button type="submit"
					className={`${classes.submitBtn} ${isFormValid && !disableBtn ? classes.enabled : ''}`}
					disabled={!isFormValid || name?.trim().length <= 0 || email?.trim().length <= 0 || disableBtn}>
					Submit
				</button>
			</form>
		</div>
	);
}
