import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	chatBtn: {
		background: "transparent",
		width: "75px",
		height: "75px",
		position: "fixed",
		bottom: "0px",
		right: "0px",
		cursor: "pointer",
		transition: "all 0.5s ease-in-out",
		border: "none",
		margin: "5px 5px 0 5px ",
		zIndex: "10",

		"&:hover": {
			opacity: 0.9,
		},
		"&:focus": {
			outlineColor: "var(--active-brand-color)",
		},
	},
	chatBtnOpen: {
		background: "transparent",
		width: "75px",
		height: "75px",
		position: "static",
		bottom: "0px",
		right: "0px",
		cursor: "pointer",
		transition: "all 0.5s ease-in-out",
		border: "none",
		margin: "5px 5px 0 5px ",

		"&:hover": {
			opacity: 0.9,
		},
		"&:focus": {
			outlineColor: "var(--active-brand-color)",
		},
	},

	'@media (max-height: 320px)': {
		chatBtn: {
			display: chatOpen => chatOpen ? "none" : "block",
		}
	},
	animateSvg: {
		animation: "fadeIn 0.5s ease-in-out",
	},

	welcomeMessage: {
		alignItems: "center",
		display: "flex",
		justifyContent: "center",
		borderRadius: "6px",
		position: "fixed",
		width: "fit-content",
		height: "fit-content",
		bottom: "80px",
		right: "5px",
		fontSize: "16px",
		padding: "10px",
		backgroundColor: "#ffffff",
		boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
		animation: "fadeIn 0.5s ease-in-out",
	},
	bubble: {
		backgroundImage: `url('../images/openChatBlank.svg')`,
		backgroundSize: "cover",
		width: "75px",
		height: "75px",
		position: "fixed",
		bottom: "0px",
		right: "0px",
		cursor: "pointer",
		transition: "all 0.5s ease-in-out",
		border: "none",
		margin: "5px 0 0 5px",
	},
});

export default function ChatWindowButton({ chatOpen, toggleChatWindow }) {
	const classes = useStyles(chatOpen);
	const [welcomeMessage, setWelcomeMessage] = useState(
		"We're online! How can we help?"
	);

	return (
		<div style={{ textAlign: chatOpen ? 'end' : null }}>
			{chatOpen ? (
				<button
					className={classes.chatBtnOpen}
					aria-label="Close Chat Window"
					onClick={() => toggleChatWindow()}
				>
					<svg
						aria-hidden="true"
						width="64"
						height="59"
						viewBox="0 0 64 59"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M0.886458 58.4531C8.18835 58.4531 15.3331 58.5295 22.4129 58.5172H22.4622C27.2275 58.5172 31.9287 58.4605 36.6867 58.3274C40.2514 58.2263 43.9816 57.0654 47.2899 55.5765C57.592 51.0214 63.2524 42.0344 63.7453 32.8033C63.8587 31.9949 63.9351 31.1839 63.9721 30.368C64.7166 14.2696 50.4355 0.0794349 33.679 0.0794349C26.4434 0.0695747 19.4444 2.6503 13.9444 7.35311C8.44686 12.0561 4.81541 18.5706 3.7034 25.7187C2.59404 32.8668 4.07812 40.1777 7.89186 46.325C7.84009 46.7958 7.70204 47.2519 7.48017 47.6684C5.44876 51.208 3.23989 54.6417 0.885742 58.4524L0.886458 58.4531Z"
							fill="white"
						/>
						<path
							className={classes.animateSvg}
							d="M34.6705 36.9909C35.0441 36.9492 35.3978 36.7708 35.6819 36.484L43.3189 28.8006C43.7038 28.4388 43.9468 27.9103 43.9922 27.335C44.0377 26.7597 43.8828 26.1861 43.5632 25.7476C43.2422 25.3074 42.7847 25.0406 42.2932 25.0039C41.8017 24.9689 41.3187 25.169 40.955 25.5592L34.5 32.0555L28.045 25.5592C27.6813 25.169 27.1983 24.9689 26.7068 25.0039C26.2153 25.0389 25.7578 25.3074 25.4368 25.7459C25.1172 26.1861 24.9623 26.758 25.0078 27.335C25.0532 27.9103 25.2962 28.4388 25.6811 28.8006L33.3181 36.484C33.6917 36.8609 34.1804 37.0443 34.6705 36.9909Z"
							fill="var(--active-brand-color)"
						/>
					</svg>
				</button>
			) : (
				<>
					<div className={classes.welcomeMessage}>{welcomeMessage} </div>
					<button
						className={classes.chatBtn}
						aria-label="Open Chat Window"
						onClick={() => toggleChatWindow()}
					>
						<svg
							aria-hidden="true"
							width="65"
							height="59"
							viewBox="0 0 65 59"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect
								x="12.8306"
								y="23.9605"
								width="40.0861"
								height="10.2347"
								fill="white"
							/>
							<path
								d="M0.890731 58.4531C8.19263 58.4531 15.3374 58.5295 22.4171 58.5172H22.4664C27.2318 58.5172 31.933 58.4605 36.6909 58.3274C40.2557 58.2263 43.9859 57.0653 47.2941 55.5765C57.5963 51.0214 63.2567 42.0344 63.7496 32.8033C63.863 31.9949 63.9394 31.1839 63.9764 30.368C64.7209 14.2696 50.4398 0.0794349 33.6833 0.0794349C26.4477 0.0695747 19.4487 2.6503 13.9486 7.35311C8.45113 12.0561 4.81968 18.5706 3.70767 25.7187C2.59832 32.8668 4.08239 40.1777 7.89614 46.325C7.84437 46.7958 7.70631 47.2519 7.48444 47.6684C5.45304 51.208 3.24417 54.6417 0.890015 58.4524L0.890731 58.4531ZM33.7082 25.6602C35.1751 25.6602 36.4989 26.5451 37.061 27.9008C37.6231 29.2565 37.3124 30.8168 36.2746 31.8545C35.2367 32.8922 33.6762 33.2027 32.3203 32.6408C30.9644 32.0788 30.0794 30.7551 30.0794 29.2885C30.0818 27.287 31.7064 25.6627 33.7082 25.6602ZM20.9359 25.6602C22.4027 25.6627 23.7241 26.5475 24.2837 27.9033C24.8434 29.2614 24.5303 30.8192 23.4924 31.8569C22.4545 32.8922 20.894 33.2027 19.5381 32.6408C18.1823 32.0788 17.2997 30.7551 17.2997 29.2885C17.2972 28.3248 17.6818 27.3979 18.3647 26.7176C19.05 26.0349 19.977 25.6553 20.9408 25.6602H20.9359Z"
								fill="white"
							/>
							<path
								className={classes.animateSvg}
								d="M45.0152 26.5639H43.6798V25.5087C43.6798 22.9931 41.63 20.9747 39.1458 20.9747H33.7436V17.9934C33.7436 17.4658 33.3094 17 32.7503 17C32.2227 17 31.7569 17.4342 31.7569 17.9934V20.9747H26.3535C23.8379 20.9747 21.8195 23.0246 21.8195 25.5087V26.5639H20.484C19.1183 26.5639 18 27.6823 18 29.048L18.0012 32.5253C18.0012 33.8911 19.1195 35.0094 20.4853 35.0094H21.8207V36.0646C21.8207 38.5802 23.8705 40.5986 26.3547 40.5986H39.1472C41.6628 40.5986 43.6812 38.5488 43.6812 36.0646L43.68 35.0094H45.0154C46.3811 35.0094 47.4995 33.8911 47.4995 32.5253V29.048C47.4995 27.6811 46.3811 26.5639 45.0154 26.5639H45.0152ZM20.4853 33.0228C20.2063 33.0228 19.9577 32.8057 19.9577 32.4952V29.0479C19.9577 28.769 20.1748 28.5203 20.4853 28.5203H21.8207V33.0226L20.4853 33.0228ZM41.7233 36.0344C41.7233 37.4633 40.5746 38.6119 39.1458 38.6119H26.3533C24.9245 38.6119 23.7758 37.4633 23.7758 36.0344L23.7771 25.5077C23.7771 24.0788 24.9257 22.9302 26.3545 22.9302H39.147C40.5758 22.9302 41.7245 24.0788 41.7245 25.5077L41.7233 36.0344ZM45.5428 32.5254C45.5428 32.8044 45.3257 33.053 45.0152 33.053H43.6797V28.5203H45.0152C45.2941 28.5203 45.5428 28.7374 45.5428 29.0479V32.5254Z"
								fill="var(--active-brand-color)"
							/>
							<path
								className={classes.animateSvg}
								d="M30.8873 28.0547C30.8873 29.1863 29.9703 30.1033 28.8374 30.1033C27.7058 30.1033 26.7888 29.1864 26.7888 28.0547C26.7888 26.9218 27.7058 26.0049 28.8374 26.0049C29.9703 26.0049 30.8873 26.9218 30.8873 28.0547Z"
								fill="var(--active-brand-color)"
							/>
							<path
								className={classes.animateSvg}
								d="M38.7117 28.0547C38.7117 29.1863 37.7947 30.1033 36.6631 30.1033C35.5302 30.1033 34.6132 29.1864 34.6132 28.0547C34.6132 26.9218 35.5302 26.0049 36.6631 26.0049C37.7947 26.0049 38.7117 26.9218 38.7117 28.0547Z"
								fill="var(--active-brand-color)"
							/>
							<path
								className={classes.animateSvg}
								d="M36.9738 32.0913C36.4765 31.8741 35.887 32.0913 35.6699 32.5886C35.266 33.457 34.0858 34.078 32.7505 34.078C31.4151 34.078 30.2349 33.457 29.8322 32.5873C29.6151 32.09 29.0244 31.8729 28.5283 32.09C28.031 32.3072 27.8139 32.8979 28.031 33.3939C28.7454 34.978 30.6388 36.0332 32.7504 36.0332C34.862 36.0332 36.7553 34.978 37.4698 33.3939C37.6869 32.8978 37.4698 32.3084 36.9737 32.0913H36.9738Z"
								fill="var(--active-brand-color)"
							/>
						</svg>
					</button>
				</>
			)}
		</div>
	);
}
