import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import ResponseButton from "./ResponseButton";
import SubmitAnswerButton from "./SubmitAnswerButton";
import AnswerButton from "./AnswerButton";
import firebase from "firebase/app";
import { useAuthState } from "react-firebase-hooks/auth";
import { firestore, auth } from "../services/firebase/firebase";

const useStyles = makeStyles({
	container: {
		display: "flex",
		flexWrap: "wrap",
		margin: "12px 30px 0 30px",
		justifyContent: "flex-start",
	},
});

export default function AnswerBtnGroup({
	options,
	multipleAnswers,
	userId,
	messageId,
	messagesRef,
	online,
	linkMap,
	chatbotIsDraft,
	switchOptions,
	chatbotId
}) {
	const classes = useStyles();
	const [selected, setSelected] = useState([]);
	const [authState, authLoading, authError] = useAuthState(auth);

	const selectResponse = (messageId, value) =>{
		console.log("value", value)
		setSelected((prev) => {
			console.log("prev", prev)
			if (prev.includes(value)) {
				// Remove the value
				const updatedSelection = prev.filter((el) => el !== value);
				
				console.log("updatedSelection", updatedSelection)
				return updatedSelection;
			} else {
				// Add the value

				const updatedSelection = [...prev, value];
				console.log("updatedSelection", updatedSelection)
				return updatedSelection;
			}
		});
	}
	
	const submitResponse = async (messageId, value, selected) => {
		// setSelected(value);
		
		console.log("submitting message:", selected);
		const strSelected = selected.join(" ** ") //(", ")
		// Submit response on button click and update the options to null in the DB
		
		await messagesRef
			.add({
				text: [strSelected],
				createdAt: firebase.firestore.FieldValue.serverTimestamp(),
				uid: authState?.uid + "***" + chatbotId, // userId,
				photoUrl:
					"https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/batman_hero_avatar_comics-512.png",
				switchOptions: switchOptions,
			})
			.then((res) => {
				firestore
					.collection("rooms")
					.doc(userId)
					.collection("messages")
					.doc(messageId)
					.update({ options: null });
			})
			.catch((err) => console.error(err));
	};

	const lastAnswer = [multipleAnswers[multipleAnswers.length - 1]];

	return (
		<div className={classes.container}>
			{multipleAnswers &&
			//all element except the last one
				multipleAnswers.slice(0, -1).map((option) => (
					<AnswerButton
						key={option}
						value={option}
						messageId={messageId}
						submitResponse={submitResponse}
						selectResponse={selectResponse}
						selected={selected}
						online={online}
						linkMap={linkMap}
						chatbotIsDraft={chatbotIsDraft}
					>
						{/* Update 1 Nov 2022
						At this time the options are returing a text inside a span tag
						To remove the html tag used .replace
						*/}
						{option.replace("<span>", "").replace("</span>", "")}
					</AnswerButton>
				))}
				{multipleAnswers && 
				//omly the last one
				lastAnswer.map((option, i) => (
					<SubmitAnswerButton
						key={option}
						value={option}
						messageId={messageId}
						submitResponse={submitResponse}
						selected={selected}
						online={online}
						linkMap={linkMap}
						chatbotIsDraft={chatbotIsDraft}
					>
						{/* Update 1 Nov 2022
						At this time the options are returing a text inside a span tag
						To remove the html tag used .replace
						*/}
						{option.replace("<span>", "").replace("</span>", "")}
					</SubmitAnswerButton>
				
				))}
		</div>
	);
}
