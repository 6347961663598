import { useStateValue } from "../context/state/provider";
import { makeStyles } from "@material-ui/core/styles";
import { updateVisibilitySetting } from "../helpers/accessibilityHelpers";
import Button from "@material-ui/core/Button";
import { VISIBILITY } from "../constants/visibility.constants";

const useStyles = makeStyles({
	root: {
		width: "150px",
		minWidth: "fit-content",
		height: "42px",
		margin: "5px",
	},
	buttonRow: {
		display: "flex",
		justifyContent: "start",
		margin: "8px auto",
	},
	mono: {
		color: "var(--monochrome-text)",
		borderColor: "var(--monochrome-text)",
		transition:
			"color 0.3s ease-in-out, background 0.3s ease-in-out, font-size 0.3s ease-in-out",
		"&:focus": {
			border: "2px solid var(--monochrome-text)",
		},
	},
	monoSelected: {
		color: "white",
		background: "var(--monochrome-text)",
		border: "2px solid var(--monochrome-text)",
		outline: "none",
		"box-shadow": "0 0 0px 2px var(--active-brand-color), -1px 0 0px 1px var(--active-brand-color), 1px 0 0px 1px var(--active-brand-color), 0 -1px 0px 1px var(--active-brand-color), 0 1px 0px 1px var(--active-brand-color)", //add mar01
		"&:hover": {
			background: "var(--monochrome-text)",
			opacity: "0.9",
		},
	},
	contrast: {
		color: "var(--active-high-contrast)",
		borderColor: "var(--active-high-contrast)",

		transition:
			"color 0.3s ease-in-out, background 0.3s ease-in-out, font-size 0.3s ease-in-out",
		"&:focus": {
			border: "2px solid var(--active-high-contrast)",
		},
	},
	contrastSelected: {
		color: "white",
		background: "var(--active-high-contrast)",
		border: "2px solid var(--active-high-contrast)",
		outline: "none",
		"box-shadow": "0 0 0px 2px var(--active-brand-color), -1px 0 0px 1px var(--active-brand-color), 1px 0 0px 1px var(--active-brand-color), 0 -1px 0px 1px var(--active-brand-color), 0 1px 0px 1px var(--active-brand-color)", //add mar01
		"&:hover": {
			background: "var(--active-high-contrast)",
			opacity: "0.9",
		},
	},
	"@media (max-width: 300px)": {
		root: {
			fontSize: "calc(var(--active-font-size) - 2px)",
		},
	},
	"@media (max-width: 260px)": {
		buttonRow: {
			flexWrap: "wrap",
		},
	},
});

export default function VisibilityButtons() {
	const classes = useStyles();
	const [{ visibility, colorSettings }, dispatch] = useStateValue();

	const ToggleMonochrome = () => {
		if (
			visibility === VISIBILITY.STANDARD ||
			visibility === VISIBILITY.HIGH_CONTRAST
		) {
			dispatch({
				type: "SET_VISIBILITY",
				visibility: VISIBILITY.MONOCHROME,
			});
			localStorage.setItem("visibilitySetting", VISIBILITY.MONOCHROME);

			updateVisibilitySetting(VISIBILITY.MONOCHROME);
		} else {
			dispatch({
				type: "SET_VISIBILITY",
				visibility: VISIBILITY.STANDARD,
			});
			localStorage.setItem("visibilitySetting", VISIBILITY.STANDARD);

			updateVisibilitySetting(VISIBILITY.STANDARD, colorSettings);
		}
	};

	const ToggleContrast = () => {
		if (
			visibility === VISIBILITY.STANDARD ||
			visibility === VISIBILITY.MONOCHROME
		) {
			dispatch({
				type: "SET_VISIBILITY",
				visibility: VISIBILITY.HIGH_CONTRAST,
			});
			localStorage.setItem("visibilitySetting", VISIBILITY.HIGH_CONTRAST);

			updateVisibilitySetting(VISIBILITY.HIGH_CONTRAST);
		} else {
			dispatch({
				type: "SET_VISIBILITY",
				visibility: VISIBILITY.STANDARD,
			});
			localStorage.setItem("visibilitySetting", VISIBILITY.STANDARD);
			updateVisibilitySetting(VISIBILITY.STANDARD, colorSettings);
		}
	};

	return (
		<div className={classes.buttonRow}>
			<Button
				className={
					visibility === VISIBILITY.MONOCHROME
						? `${classes.root} ${classes.mono} ${classes.monoSelected}`
						: `${classes.root} ${classes.mono}`
				}
				onClick={() => {
					ToggleMonochrome();
				}}
			>
				Monochrome
			</Button>
			<Button
				className={
					visibility === VISIBILITY.HIGH_CONTRAST
						? `${classes.root} ${classes.contrast} ${classes.contrastSelected}`
						: `${classes.root} ${classes.contrast}`
				}
				onClick={() => {
					ToggleContrast();
				}}
			>
				High Contrast
			</Button>
		</div>
	);
}
