import { makeStyles } from "@material-ui/core/styles";
import { IoIosFlag } from "react-icons/io";
import { firestore } from "../services/firebase/firebase";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";

const useStyles = makeStyles({
	feedbackContainer: {
		position: "relative",
		bottom: "-20px",
		marginLeft: "auto",
		display: "flex",
		justifyContent: "flex-end",
	},
	feedbackBtn: {
		border: "none",
		background: "transparent",
		position: "absolute",
		bottom: "2px",
		right: "-12px",
		height: "20px",
		width: "20px",
		borderRadius: "5px",

		"&:hover": {
			cursor: "pointer",
			opacity: "0.8",
		},
	},
	flag: {
		background: "var(--user-msg-txt)",
		border: "1px solid var(--color-alt-grey)",
		borderRadius: "5px",
		padding: "1px",
		color: "var(--color-light-grey)",
		height: "15px",
		width: "15px",
		transition: "color 0.15s ease",
	},
	flagSelected: {
		color: "var(--bot-status-disconnected)",
	},
});

export default function FeedbackButton({ messageId, userId, flagged,
	handleFlag, chatbotId
}) {
	const classes = useStyles();

	const AdminFlagMessage = async () => {
		const snapRoom = await firestore
			.collection("rooms")
			.doc(userId + "***" + chatbotId)
			.get();

		const roomDdata = snapRoom.data();
		const key = roomDdata.key;
		const appKey = roomDdata.appKey;

		console.log('Key:', key);
		console.log('Appkey:', appKey);
		await axios
			.post(
				`${process.env.REACT_APP_FUNCTIONS_BASE_URL}/app/skills/rooms/${userId}***${chatbotId}/messages/${messageId}/qaAlerts`,
				{ flag: true },
				{ headers: { key, appKey } }
			)
			.then((res) => {
				console.log("Success: flag message toggled", res)
			})
			.catch((err) => {
				console.log("error flagging message", err)
			});
	};
	return (
		<div className={classes.feedbackContainer}>
			<Tooltip title="toggle QA alert">
				<button
					className={classes.feedbackBtn}
					onClick={() => {
						handleFlag();
						AdminFlagMessage()
					}}
					aria-label="mark incorrect response"
					color="inherit"
					autoFocus={false}
				>
					<IoIosFlag
						className={
							flagged
								? `${classes.flag} ${classes.flagSelected}`
								: `${classes.flag}`
						}
					/>
				</button>
			</Tooltip>
		</div>
	);
}
