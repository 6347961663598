import { useStateValue } from "../context/state/provider";
import { makeStyles } from "@material-ui/core/styles";
import { resetAccessibilitySettings } from "../helpers/accessibilityHelpers";
import Divider from "@material-ui/core/Divider";
import FontButtons from "../components/FontButtons";
import VisibilityButtons from "../components/VisibilityButtons";
import { FONT_SIZE } from "../constants/fontSize.constants";
import { VISIBILITY } from "../constants/visibility.constants";

const useStyles = makeStyles({
	accessibilityDisplay: {
		position: "absolute",
		overflowY: "auto",
		overflowX: "hidden",
		top: "75px",
		bottom: "40px",
		left: 0,
		right: 0,
		animation: "fadeInAnimation 0.4s ease",
		padding: "12px 20px",
		fontSize: "var(--active-font-size)",
	},
	headingText: {
		color: "var(--color-dark-grey)",
		fontSize: "var(--active-font-size)",
		fontWeight: 400,
		margin: "0 10px",
		textAlign: "start",
		transition: "all 0.3s ease-in-out",
	},
	section: {
		padding: "20px 0px",
		margin: "auto",
	},
	resetButton: {
		// MuiButtonBase-root 
		cursor: "pointer",
		margin: "0",
		display: "inline-flex",
		position: "relative",
		"align-items": "center",
		"user-select": "none",
		"vertical-align": "middle",
		"-moz-appearance": "none",
		"justify-content": "center",
		"text-decoration": "none",
		"-webkit-appearance": "none",
		"-webkit-tap-highlight-color": "transparent",

		// MuiButton-root 
		color: "var(--active-msg-bg)",
		border: "1px solid var(--active-msg-bg)",
		"font-size": "var(--active-font-size)",
		background: "white",
		"box-sizing": "border-box",
		"font-family": "averta std', sans-serif",
		"font-weight": "500",
		"line-height": "1.75",
		"border-radius": "42px",
		"letter-spacing": "0.02857em",
		"text-transform": "none",

		// MuiButton-text 
		padding: "6px 8px",

		//makeStyles-resetButton-55
		minWidth: "220px",
		transition:
			"color 0.3s ease-in-out, background 0.3s ease-in-out, font-size 0.3s ease-in-out",
		marginTop: "15px",

		"&:focus": {
			outline: "none",
			"box-shadow": "0 0 0px 2px var(--active-brand-color), -1px 0 0px 1px var(--active-brand-color), 1px 0 0px 1px var(--active-brand-color), 0 -1px 0px 1px var(--active-brand-color), 0 1px 0px 1px var(--active-brand-color)",
		},
	},
	resetButtonDisable: {
		// MuiButtonBase-root 
		outline: "none",
		cursor: "default", //to disable
		"pointer-events": "none", //to disable
		margin: "0",
		display: "inline-flex",
		position: "relative",
		"align-items": "center",
		"user-select": "none",
		"vertical-align": "middle",
		"-moz-appearance": "none",
		"justify-content": "center",
		"text-decoration": "none",
		"-webkit-appearance": "none",
		"-webkit-tap-highlight-color": "transparent",

		// MuiButton-root 
		color: "rgba(0, 0, 0, 0.26)", //to disable
		border: "1px solid var(--color-light-grey)", //to disable
		"font-size": "var(--active-font-size)",
		background: "white",
		"box-sizing": "border-box",
		"font-family": "averta std', sans-serif",
		"font-weight": "500",
		"line-height": "1.75",
		"border-radius": "42px",
		"letter-spacing": "0.02857em",
		"text-transform": "none",

		// MuiButton-text 
		padding: "6px 8px",

		//makeStyles-resetButton-55
		minWidth: "220px",
		transition:
			"color 0.3s ease-in-out, background 0.3s ease-in-out, font-size 0.3s ease-in-out",
		marginTop: "15px",
	},
	"@media (max-width: 350px)": {
		section: {
			padding: "15px 0px",
		},
		accessibilityDisplay: {
			padding: "15px 2px",
		},
		resetButton: {
			fontSize: "calc(var(--active-font-size) - 2px)",
			"&:disabled": {
				border: "grey",
			},
		},
	},
});

export default function AccessibilityPanel() {
	const classes = useStyles();

	const [{ colorSettings, clientId, visibility, fontSize }, dispatch] =
		useStateValue();

	const handleSettingsReset = () => {
		dispatch({
			type: "SET_VISIBILITY",
			visibility: VISIBILITY.STANDARD,
		});
		dispatch({ type: "SET_FONT_SIZE", fontSize: FONT_SIZE.STANDARD });
		localStorage.setItem("visibilitySetting", VISIBILITY.STANDARD);
		localStorage.setItem("fontSizeSetting", FONT_SIZE.STANDARD);

		resetAccessibilitySettings(colorSettings, clientId);
	};

	return (
		<div className={classes.accessibilityDisplay}>
			<div className={classes.section}>
				<h2 className={classes.headingText}>Font Sizes</h2>
				<FontButtons />
			</div>
			<Divider />
			<div className={classes.section}>
				<h2 className={classes.headingText}>Visibility</h2>
				<VisibilityButtons />
			</div>
			<Divider />
			<div className={classes.section}>
				<div
					tabIndex={0}
					className={(visibility === VISIBILITY.STANDARD &&
						fontSize === FONT_SIZE.STANDARD) ? classes.resetButtonDisable : classes.resetButton}
					onClick={() => handleSettingsReset()}
					disabled={
						visibility === VISIBILITY.STANDARD &&
						fontSize === FONT_SIZE.STANDARD
					}
				>
					Reset Display Settings
				</div>{" "}
			</div>
		</div>
	);
}
